<template>
    <div>
        <b-card title="Edit Aforo Door">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row></b-row>
                    <b-row>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <h5>Id</h5>
                            {{ body.pkid_door }}
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <h5>Aforo</h5>
                            {{ aforoSelected }}
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Name" rules="required">
                                <h5>Name</h5>
                                <b-form-input v-if="!disable" v-model="body.Name" :disabled="disable" />
                                <div v-else>
                                    {{ body.Name }}
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                        <b-col>
                            <validation-provider #default="{ errors }" name="Acces SCA" rules="required">
                                <h5>SCA Acces</h5>
                                <v-select v-if="!disable" v-model="selected" multiple :options="doors">
                                    <span slot="no-options">No options for selected Aforo</span>
                                </v-select>
                                <div v-else>
                                    {{ selected.toString() }}
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <b-row></b-row>
            <b-row style="margin-top: 20px">
                <b-col></b-col>
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="validationForm"
                    :hidden="disable">
                    <feather-icon icon="UploadIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Edit Aforo Acces
                </b-button>
                <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/aforodoor">
                    <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
                    Go Back
                </b-button>
                <b-col></b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BFormSelect } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import router from "@/router";
import { ref } from "@vue/composition-api";
import axios from "@/libs/axios";
import vSelect from 'vue-select'
import { decryptString } from '@/libs/crypto'

export default {
    components: {
        BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol, BFormSelect, ValidationProvider, ValidationObserver,
        vSelect
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.message();
                }
            })
        },
        async message() {
            const error = await this.onSubmit();
            if (error[0]) {
                const variant = "danger";
                this.$bvToast.toast("" + error[1], {
                    title: "Failed",
                    variant,
                    solid: true,
                });
            } else if (!error[0]) {
                this.disable = true;
                const variant = "success";
                this.$bvToast.toast("Aforo Door updated correctly", {
                    title: `Update`,
                    variant,
                    solid: true,
                });
            }
        },
    },
    data() {
        return {
            required,
            disable: false,
            aforos: [],
            aforoSelected: "",
            doors: [],
            selected: [],
        };
    },
    watch: {
        selected(newV) {
            this.body.AccesSCA = newV.toString();
        },
    },
    async created() {
        await axios
            .get("/aforo")
            .then((results) => {
                results.data.forEach(element => {
                    this.aforos.push(element.Aforo_name + " - (" + element.ID_Aforo + ")")
                });
            })
            .catch((error) => {
                const variant = "danger";
                this.$bvToast.toast("Failed to connect", {
                    title: `` + error,
                    variant,
                    solid: true,
                });
            });
        axios
            .get("/aforodoor" + "?pkid_door=" + router.currentRoute.params.id)
            .then((result) => {
                this.body = result.data[0]
                this.aforoSelected = this.body.Aforo_name + " - (" + this.body.ID_Aforo + ")"
                axios
                    .get("/aforoacces?ID_Aforo=" + this.body.ID_Aforo)
                    .then((results) => {
                        results.data.forEach(element => {
                            this.doors.push(element.AccesSCA)
                        });
                    })
                    .catch((error) => {
                        const variant = "danger";
                        this.$bvToast.toast("Failed to connect", {
                            title: `` + error,
                            variant,
                            solid: true,
                        });
                    });
                const accesSCA = this.body.AccesSCA.split(",")
                accesSCA.forEach(element => {
                    this.selected.push(element)
                })
            })
            .catch();
    },
    mounted() {
        if (localStorage.getItem("accessToken") === null) {
            this.$router.go(0)
        }
    },
    setup() {
        const blankCompanyData = {
            pkid_door: "",
            ID_Aforo: "",
            Name: "",
            AccesSCA: "",
            IdUser: decryptString(localStorage.getItem('idUser')),
        };

        const body = ref(JSON.parse(JSON.stringify(blankCompanyData)));

        const onSubmit = async () => {
            const onError = [false, ""];
            await axios
                .put("/aforodoor", body.value)
                .then((result) => {
                    onError[0] = false;
                })
                .catch((error) => {
                    onError[0] = true;
                    onError[1] = error;
                });
            return onError;
        };


        return {
            body,
            onSubmit,
        };
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
